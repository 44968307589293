<template>
  <!--                          :elevation="isSticky ? 2 : 0"-->
  <v-toolbar
    :class="`rounded-b-lg ${isSticky ? 'sticky-elevation' : ''}`"
    height="40px"
    flat
    dense
  >
    <template v-slot:img="{ props }">
      <v-img
        v-show="isSticky"
        v-bind="props"
        :src="require('@/assets/background/colorful/bg-star.jpg')"
        gradient="to bottom, rgba(255,255,255,0.8), rgba(255,255,255,1)"
      >
      </v-img>
    </template>
    <v-toolbar-title style="font-size: 1rem; font-weight: 900">{{
      text
    }}</v-toolbar-title>

    <v-spacer></v-spacer>

    <v-btn icon>
      <v-icon small>mdi-magnify</v-icon>
    </v-btn>
  </v-toolbar>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "toolBar",
  props: {
    text: {
      type: String,
      default: "往期",
    },
    isSticky: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isApp: "isApp",
      appTheme: "appTheme",
      systemBarHeight: "systemBarHeight",
    }),
  },
  data: () => ({}),
  methods: {},
};
</script>

<style lang="scss" scoped>
.sticky-elevation {
  box-shadow: 0px 5px 3px -2px rgb(0 0 0 / 20%) !important;
}
</style>
